import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@avenir-client-web/button';
import { DialogContentLayoutModule } from '@avenir-client-web/dialog-content-layout';
import { FileUploadModule } from 'primeng/fileupload';
import { UploadImageButtonComponent } from './components/upload-image-button/upload-image-button.component';
import { ImageComponent } from './containers/image/image.component';
import { UploadImageComponent } from './containers/upload-image/upload-image.component';

@NgModule({
  declarations: [
    ImageComponent,
    UploadImageComponent,
    UploadImageButtonComponent,
  ],
  imports: [
    CommonModule,
    ButtonModule,
    DialogContentLayoutModule,
    ScrollingModule,
    FileUploadModule,
  ],
  exports: [ImageComponent, UploadImageComponent, UploadImageButtonComponent],
})
export class ImageModule {}
