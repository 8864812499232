import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterOption } from '../interfaces/search.interfaces';

@Component({
  selector: 'app-search-section',
  templateUrl: './search-section.component.html',
  styleUrls: ['./search-section.component.scss'],
})
export class SearchSectionComponent<T = unknown> {
  @Input() sectionTitle: string;

  @Input() placeholder: string;

  @Input() resultQuantity: number;

  @Input() resultsFoundText: string;

  @Input() options: FilterOption<T>[];

  @Input() filter: T;

  @Input() keyword: string;

  @Output() loadEvent = new EventEmitter<void>();
}
