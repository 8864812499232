<div class="search-layout">
  <div class="search-layout__search">
    <ng-content select="[slot='search']"></ng-content>
  </div>

  <div class="search-layout__body">
    <div class="search-layout__filter mt-5">
      <ng-content select="[slot='filter']"></ng-content>
    </div>

    <div class="search-layout__results">
      <ng-content select="[slot='results']"></ng-content>
    </div>
  </div>
</div>
