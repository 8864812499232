export enum ImageType {
  ALL = 'image/*',
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  SVG = 'image/svg+xml',
}

export enum ImageTypeForUpload {
  PNG = '.png',
  JPEG = '.jpeg, .jpg',
  SVG = '.svg',
}
