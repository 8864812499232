import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthMsalService } from '@app/auth-msal/services/auth-msal.service';
import { ShellModule } from '@app/shell/shell.module';
import { LanguageService } from '@avenir-client-web/language';
import { ProgressBarModule } from '@avenir-client-web/progress-bar';
import { ProgressSpinnerModule } from '@avenir-client-web/progress-spinner';
import { CoreModule } from '@core/core.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DummyComponent } from './dummy/dummy.component';

function localeFactory(languageService: LanguageService): string {
  const locale = languageService.getLocale();

  languageService.setPreferredLocale(locale);

  return locale;
}

@NgModule({
  declarations: [AppComponent, DummyComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ShellModule,
    CoreModule,
    BrowserAnimationsModule,
    NgxPermissionsModule.forRoot(),
    ProgressBarModule,
    ProgressSpinnerModule,
    ToastModule,
  ],
  providers: [
    MessageService,
    AuthMsalService,
    { provide: LOCALE_ID, useFactory: localeFactory, deps: [LanguageService] },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
