import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, BaseAppConfig } from '@avenir-client-web/models';
import { PreferredLanguageService } from './preferred-language.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private readonly supportedLanguages = this.config.supportedLanguages;

  constructor(
    private readonly preferredLanguageService: PreferredLanguageService,
    @Inject(APP_CONFIG) private readonly config: BaseAppConfig
  ) {}

  getLocale(): string {
    const urlLocale = this.getLocaleFromUrl();

    if (this.isLocaleSupported(urlLocale)) {
      return urlLocale;
    } else {
      return this.preferredLanguageService.getPreferredLocale();
    }
  }

  switchLanguage(locale: string): void {
    if (!this.isLocaleSupported(locale)) {
      return;
    }

    this.setPreferredLocale(locale);
    this.replaceLocationHref(this.getLocale(), locale);
  }

  setPreferredLocale(locale: string): void {
    this.preferredLanguageService.setPreferredLocale(locale);
  }

  private getLocaleFromUrl(): string {
    return window.location.pathname.split('/')[1];
  }

  private replaceLocationHref(currentLocale: string, newLocale: string): void {
    window.location.href = window.location.pathname.replace(
      `/${currentLocale}/`,
      `/${newLocale}/`
    );
  }

  private isLocaleSupported(locale: string): boolean {
    return this.supportedLanguages.some(l => l === locale);
  }
}
