import { Injectable, SecurityContext } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { FileResponseType, FileService } from '@avenir-client-web/file';
import { SafePipe } from '@avenir-client-web/safe-pipe';
import { SanitizerPipe } from '@avenir-client-web/sanitizer';
import { catchError, Observable, of } from 'rxjs';
import { ImageType } from '../enums/image-types.enum';
import { svgToDataURL } from '../utils/svg-to-dataurl.utils';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  constructor(
    private readonly fileService: FileService,
    private readonly sanitizer: SanitizerPipe,
    private readonly safe: SafePipe
  ) {}

  /**
   * Constructs a `GET` request that interprets the body as a blob object and returns
   * the SafeUrl body in given mime type.
   *
   * @param imageId The file identifier.
   * @param isThumbnails
   * @param isSvg
   * @returns An `Observable` of the `SafeUrl`, with a response body in the requested type.
   */
  getImageUrl(
    imageId: string,
    isThumbnails?: boolean,
    isSvg?: boolean
  ): Observable<SafeUrl> {
    const responseType = isSvg ? FileResponseType.SVG : FileResponseType.BLOB;

    return new Observable(observer => {
      this.fileService
        .getFile(imageId, ImageType.ALL, responseType, isThumbnails)
        .pipe(catchError(() => of('')))
        .subscribe(data => {
          if (!data) {
            observer.next();
            observer.complete();

            return;
          }

          if (isSvg) {
            observer.next(
              this.safe.transform(
                svgToDataURL(data as string),
                SecurityContext.URL
              )
            );

            return;
          }

          const blob = new Blob([data], {
            type: isSvg ? ImageType.SVG : ImageType.PNG,
          });
          const reader = new FileReader();

          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            observer.next(
              this.sanitizer.transform(
                reader.result as string,
                SecurityContext.URL
              )
            );
            observer.complete();
          };
        });
    });
  }
}
