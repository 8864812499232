import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { UserActiveGuard } from '@app/auth-msal/guards/user-active.guard';
import { UserFeatureGuard } from '@app/auth-msal/guards/user-feature.guard';
import { DummyComponent } from '@app/dummy/dummy.component';
import { ShellComponent } from '@app/shell/shell.component';
import { MsalGuard } from '@azure/msal-angular';
import { menu } from '@core/constants/menu-items.constant';
import { SigningInComponent } from '@core/containers/signing-in/signing-in.component';
import { AppRootRoute } from '@core/enums/app-route.enum';
import { Permission } from '@core/services/permission.model';

const shellRoutes: Routes = [
  {
    path: 'change-role/:roleId',
    component: DummyComponent,
    canActivate: [MsalGuard, UserFeatureGuard],
    data: {
      permission: Permission.READ,
    },
  },
  {
    path: 'delete-file/:fileId',
    component: DummyComponent,
    canActivate: [MsalGuard, UserFeatureGuard],
    data: {
      permission: Permission.READ,
    },
  },
  {
    path: AppRootRoute.HOME,
    loadChildren: () =>
      import('./home/home.module').then(m => {
        return m.HomeModule;
      }),
    canLoad: [MsalGuard, UserFeatureGuard],
    canActivate: [MsalGuard, UserFeatureGuard],
    data: {
      permission: menu.dashboard.state.permission,
    },
  },
  {
    path: AppRootRoute.PROCESSES,
    loadChildren: () =>
      import('./processes/processes.module').then(m => m.ProcessesModule),
    canLoad: [MsalGuard, UserFeatureGuard],
    canActivate: [MsalGuard, UserFeatureGuard],
    data: {
      permission: Permission.READ,
    },
  },
  {
    path: AppRootRoute.LANGUAGE,
    loadChildren: () =>
      import('./tenant-language/tenant-language.module').then(
        m => m.TenantLanguageModule
      ),
    canLoad: [MsalGuard, UserFeatureGuard],
    canActivate: [MsalGuard, UserFeatureGuard],
    data: {
      permission: menu.language.state.permission,
    },
  },
  {
    path: AppRootRoute.CONTENT_CATEGORIES,
    loadChildren: () =>
      import('./content-category/content-category.module').then(
        m => m.ContentCategoryModule
      ),
    canLoad: [MsalGuard, UserFeatureGuard],
    canActivate: [MsalGuard, UserFeatureGuard],
    data: {
      permission: Permission.READ,
    },
  },
  {
    path: AppRootRoute.FILES,
    loadChildren: () =>
      import('./tenant-file/tenant-file.module').then(m => m.TenantFileModule),
    canLoad: [MsalGuard, UserFeatureGuard],
    canActivate: [MsalGuard, UserFeatureGuard],
    data: {
      permission: menu.files.state.permission,
    },
  },
  {
    path: AppRootRoute.NEWS,
    loadChildren: () => import('./news/news.module').then(m => m.NewsModule),
    canLoad: [MsalGuard, UserFeatureGuard],
    canActivate: [MsalGuard, UserFeatureGuard],
    data: {
      permission: menu.news.state.permission,
    },
  },
  {
    path: AppRootRoute.WIKI,
    loadChildren: () =>
      import('./wiki-page/wiki-page.module').then(m => m.WikiPageModule),
    canLoad: [MsalGuard, UserFeatureGuard],
    canActivate: [MsalGuard, UserFeatureGuard],
    data: {
      permission: menu.wiki.state.permission,
    },
  },
  {
    path: AppRootRoute.QUICKLINKS,
    loadChildren: () =>
      import('./quicklink/quicklink.module').then(m => m.QuicklinkModule),
    canLoad: [MsalGuard, UserFeatureGuard],
    canActivate: [MsalGuard, UserFeatureGuard],
    data: {
      permission: menu.quicklinks.state.permission,
    },
  },
  {
    path: AppRootRoute.PROFILE,
    loadChildren: () =>
      import('./profile/profile.module').then(m => {
        return m.ProfileModule;
      }),
    canLoad: [MsalGuard, UserFeatureGuard],
    canActivate: [MsalGuard, UserFeatureGuard],
    data: {
      permission: menu.dashboard.state.permission,
    },
  },
  {
    path: AppRootRoute.SEARCH,
    loadChildren: () =>
      import('./search/search.module').then(m => {
        return m.SearchModule;
      }),
    canLoad: [MsalGuard, UserFeatureGuard],
    canActivate: [MsalGuard, UserFeatureGuard],
    data: {
      permission: Permission.READ,
    },
  },
  {
    path: '',
    redirectTo: `/${AppRootRoute.HOME}`,
    pathMatch: 'full',
  },
];

const routes: Routes = [
  {
    path: '',
    component: ShellComponent,
    children: shellRoutes,
  },
  {
    path: AppRootRoute.DOCUMENT,
    loadChildren: () =>
      import('./document/document.module').then(m => {
        return m.DocumentModule;
      }),
    canLoad: [MsalGuard],
    canActivate: [MsalGuard],
  },
  {
    path: `${AppRootRoute.SIGN_IN}`,
    component: SigningInComponent,
    canLoad: [MsalGuard, UserActiveGuard],
    canActivate: [MsalGuard, UserActiveGuard],
  },
  { path: '**', redirectTo: `/${AppRootRoute.HOME}`, pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      canceledNavigationResolution: 'computed',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
