<div class="field">
  <label *ngIf="label" class="block" [ngClass]="{ required: hasAsterisk }">{{
    label
  }}</label>
  <p-dropdown
    appendTo="body"
    [options]="options"
    [showClear]="false"
    [disabled]="disabled"
    [optionLabel]="optionLabel"
    [optionValue]="optionValue"
    [placeholder]="placeholder"
    i18n-emptyMessage
    emptyMessage="default.dropdown.empty"
    dropdownIcon="fa-solid fa-angle-down"
    [styleClass]="styleClass"
    [panelStyleClass]="styleClass"
    (onChange)="onValueChange($event.value); valueChange.emit($event.value)"
    [(ngModel)]="value"
    [virtualScroll]="virtualScroll"
    [scrollHeight]="scrollHeight"
    itemSize="30"
  ></p-dropdown>
  <ng-content></ng-content>
</div>
