import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AzPaging, Paging, RequestOption } from '@avenir-client-web/models';
import { Observable } from 'rxjs';
import { HttpParameterEncoder } from '../utils/http-parameter-encoder';

@Injectable({ providedIn: 'root' })
export class HttpService {
  constructor(private readonly http: HttpClient) {}

  get<T>(url: string, options?: {}): Observable<T> {
    return this.http.get<T>(url, options);
  }

  post<T>(url: string, body: any, options?: {}): Observable<T> {
    return this.http.post<T>(url, body, options);
  }

  patch<T>(url: string, body: any, options?: {}): Observable<T> {
    return this.http.patch<T>(url, body, options);
  }

  put<T>(url: string, body: any, options?: {}): Observable<T> {
    return this.http.put<T>(url, body, options);
  }

  delete<T>(url: string, options?: {}): Observable<T> {
    return this.http.delete<T>(url, options);
  }

  buildRequestUrl(
    url: string,
    requestOption: RequestOption<Paging | AzPaging>
  ): string {
    let httpParams = new HttpParams({ encoder: new HttpParameterEncoder() });

    if (requestOption.paging) {
      for (const key in requestOption.paging) {
        httpParams = httpParams.append(key, requestOption.paging[key]);
      }
    }

    if (requestOption.sortBy) {
      httpParams = httpParams.append('sortBy', requestOption.sortBy);
    }

    if (requestOption.filter) {
      for (const key in requestOption.filter) {
        httpParams = httpParams.append(key, requestOption.filter[key]);
      }
    }

    return `${url}?${httpParams.toString()}`;
  }
}
