import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, BaseAppConfig, LogLevel } from '@avenir-client-web/models';

@Injectable({ providedIn: 'root' })
export class LoggerService {
  constructor(@Inject(APP_CONFIG) private readonly config: BaseAppConfig) {}

  error(message: any, ...args: any[]): void {
    if (args.length) {
      console.error(`[ERROR] - ${JSON.stringify(message)}`, ...args);
    } else {
      console.error(`[ERROR] - ${JSON.stringify(message)}`);
    }
  }

  warn(message: any, ...args: any[]): void {
    if (!this.isEnableLogFor(LogLevel.WARNING)) {
      return;
    }

    if (args.length) {
      console.warn(`[WARN] - ${message}`, ...args);
    } else {
      console.warn(`[WARN] - ${message}`);
    }
  }

  info(message: any, ...args: any[]): void {
    if (!this.isEnableLogFor(LogLevel.INFO)) {
      return;
    }

    if (args.length) {
      console.log(`[INFO] - ${message}`, ...args);
    } else {
      console.log(`[INFO] - ${message}`);
    }
  }

  debug(message: any, ...args: any[]): void {
    if (!this.isEnableLogFor(LogLevel.DEBUG)) {
      return;
    }

    if (args.length) {
      console.log(`[DEBUG] - ${message}`, ...args);
    } else {
      console.log(`[DEBUG] - ${message}`);
    }
  }

  private isEnableLogFor(logLevelToCompare: LogLevel): boolean {
    return (
      !this.loggingIsTurnedOff() && this.config.logLevel >= logLevelToCompare
    );
  }

  private loggingIsTurnedOff(): boolean {
    const logLevel = this.config.logLevel;

    if (logLevel === null || logLevel === undefined) {
      console.warn(
        `[WARN] - Application was started without a logLevel configured`,
        logLevel
      );

      return true;
    }

    return logLevel === LogLevel.OFF;
  }
}
