import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuGroups } from '@app/shell/models/sidebar.models';
import { SidebarService } from '@app/shell/services/sidebar.service';
import { AppActionRoute, AppRootRoute } from '@core/enums/app-route.enum';
import { Permission } from '@core/services/permission.model';
import { Observable, repeatWhen, share } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SideBarComponent implements OnInit {
  isDisplay = false;

  permission = Permission;

  sidebarItems$: Observable<MenuGroups>;

  constructor(
    private readonly sidebarService: SidebarService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.setSidebarItems();
  }

  toggleSidebar(isDisplay: boolean): void {
    this.isDisplay = isDisplay;
  }

  isCategoryFormRouter(): boolean {
    return (
      this.router.url ===
      `/${AppRootRoute.CONTENT_CATEGORIES}/${AppActionRoute.CREATE}`
    );
  }

  private setSidebarItems(): void {
    const menuChangedNotifier = (): Observable<void> =>
      this.sidebarService.menuChangedEvent$.asObservable();

    this.sidebarItems$ = this.sidebarService
      .getSidebarItems()
      .pipe(repeatWhen(menuChangedNotifier), share());
  }
}
