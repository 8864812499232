export enum SidebarItemKey {
  DASHBOARD = 'dashboard',
  NEWS = 'news',
  WIKI = 'wiki',
  PROCESSES = 'processes',
  FILES = 'files',
  CONTENT_CATEGORIES = 'content-categories',
  QUICKLINKS = 'quicklinks',
  LANGUAGE = 'language',
}
