import { Component, OnDestroy, OnInit } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { AuthMsalService } from '@app/auth-msal/services/auth-msal.service';
import { MyProfileRoute } from '@app/profile/models/profile.models';
import { filterOptions } from '@app/search/constants/filter-options.constant';
import { FilterOptionValue } from '@app/search/enums/filter-option-value.enum';
import { ProgressSpinnerService } from '@avenir-client-web/progress-spinner';
import { QueryOption } from '@avenir-client-web/search';
import { CredentialsService } from '@core/authentication/credentials.service';
import { AppRootRoute } from '@core/enums/app-route.enum';
import { User } from '@core/models/profile.model';
import { TenantRole } from '@core/models/role.model';
import { UserPermission } from '@core/services/permission.model';
import { PermissionService } from '@core/services/permission.service';
import { ProfileService } from '@core/services/profile.service';
import { MenuItem, PrimeIcons } from 'primeng/api';
import {
  filter as filterOperator,
  map,
  Observable,
  startWith,
  Subject,
  takeUntil,
} from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  profile: User = this.profileService.profile;

  items: MenuItem[];

  filterOptions = filterOptions;

  hideTopBarSearch$: Observable<boolean>;

  filterOptionValue = FilterOptionValue;

  avatarUrl$: Observable<SafeUrl>;

  private readonly compUnsubscribe$ = new Subject<void>();

  constructor(
    private readonly authMsalService: AuthMsalService,
    private readonly credentialsService: CredentialsService,
    private readonly permissionService: PermissionService,
    private readonly router: Router,
    private readonly profileService: ProfileService,
    private readonly progressSpinnerService: ProgressSpinnerService
  ) {
    this.setTopBarSearchState();
  }

  ngOnInit(): void {
    this.setMenuItems();
    this.getAvatarUrl();

    this.permissionService.roleDummyChanges$.subscribe(() => {
      this.credentialsService.onRoleSwitched$.next();
      this.setMenuItems();
    });
  }

  ngOnDestroy(): void {
    this.compUnsubscribe$.next();
    this.compUnsubscribe$.complete();
  }

  navigateToSearchResultsPage({
    keyword,
    filter,
  }: QueryOption<FilterOptionValue>): void {
    this.router.navigate([AppRootRoute.SEARCH], {
      queryParams: {
        keyword,
        filter,
      },
    });
  }

  private getAvatarUrl(): void {
    this.avatarUrl$ = this.profileService
      .getAvatarUrl(this.profile.aadId)
      .pipe(takeUntil(this.compUnsubscribe$));
  }

  private setTopBarSearchState(): void {
    this.hideTopBarSearch$ = this.router.events.pipe(
      filterOperator(event => event instanceof NavigationEnd),
      map(event => (event as NavigationEnd).url),
      startWith(this.router.url),
      map(url => url.includes(`/${AppRootRoute.SEARCH}`)),
      takeUntil(this.compUnsubscribe$)
    );
  }

  private logout(): void {
    this.router
      .navigate(['/', AppRootRoute.LOGOUT])
      .then(isNavigated => isNavigated && this.authMsalService.logout());
  }

  private switchRole(role: TenantRole): void {
    this.router.navigate([AppRootRoute.HOME]).then(isNavigated => {
      if (isNavigated || this.router.url === `/${AppRootRoute.HOME}`) {
        this.progressSpinnerService.showProgressSpinner(true);
        this.permissionService
          .setCurrentRole(role.id)
          .pipe(takeUntil(this.compUnsubscribe$))
          .subscribe(() => {
            this.credentialsService.onRoleSwitched$.next();

            const credential = {
              ...this.credentialsService.getCredentials(),
              role,
            };

            this.credentialsService.setCredentials(credential);

            this.setMenuItems();

            this.progressSpinnerService.showProgressSpinner(false);
          });
      }
    });
  }

  private setMenuItems(): void {
    const { role: currentRole } = this.credentialsService.getCredentials();

    const { userPermissions } = this.permissionService;

    const roles: MenuItem[] = this.getRoleSubMenuItems(
      userPermissions,
      currentRole
    );

    this.items = [
      {
        label: $localize`menuItems.editProfile`,
        icon: PrimeIcons.COG,
        command: () => this.goToProfile(),
      },
      {
        label: $localize`menuItems.viewRole`,
        icon: PrimeIcons.EYE,
        items: roles,
        visible: userPermissions.length > 1,
      },
      {
        label: $localize`menuItems.logout`,
        icon: PrimeIcons.SIGN_OUT,
        command: () => this.logout(),
      },
    ];
  }

  private goToProfile(): void {
    this.router.navigate([AppRootRoute.PROFILE, MyProfileRoute]);
  }

  private getRoleSubMenuItems(
    userPermissions: UserPermission[],
    currentRole: TenantRole
  ): MenuItem[] {
    return userPermissions.map(({ role }) => ({
      label: role.name,
      command: () => currentRole.id !== role.id && this.switchRole(role),
      styleClass: currentRole.id !== role.id ? '' : 'highlight',
    }));
  }
}
