import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControlComponent } from '@avenir-client-web/base-control';
import { FileItem } from '@avenir-client-web/file';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { filter, Observable, of, Subject, switchMap, takeUntil } from 'rxjs';
import { UploadImageComponent } from '../../containers/upload-image/upload-image.component';

@Component({
  selector: 'app-upload-image-button',
  templateUrl: './upload-image-button.component.html',
  styleUrls: ['./upload-image-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => UploadImageButtonComponent),
    },
  ],
})
export class UploadImageButtonComponent
  extends BaseControlComponent<FileItem>
  implements OnDestroy
{
  @Output() loaded: EventEmitter<void> = new EventEmitter();

  @Input() acceptImageFileTypes: string[];

  @Input() isSvg: boolean;

  dialogRef: DynamicDialogRef;

  private readonly compUnsubscribe$ = new Subject<void>();

  constructor(private readonly dialogService: DialogService) {
    super();
  }

  ngOnDestroy(): void {
    this.closeDialogRef();

    this.compUnsubscribe$.next();
    this.compUnsubscribe$.complete();
  }

  showUploadImageDialog(): void {
    this.dialogRef = this.dialogService.open(UploadImageComponent, {
      closable: false,
      closeOnEscape: false,
      showHeader: false,
      styleClass: 'content-layout-dialog',
      data: {
        acceptImageFileTypes: this.acceptImageFileTypes,
        isSvg: this.isSvg,
      },
    });

    this.dialogRef.onClose
      .pipe(
        filter(Boolean),
        switchMap((img: Observable<FileItem> | FileItem) =>
          img instanceof Observable ? img : of(img)
        ),
        takeUntil(this.compUnsubscribe$)
      )
      .subscribe((img: FileItem) => {
        this.value = img;
        this.onValueChange(img);
        this.loaded.emit();
      });
  }

  removeImage(): void {
    this.value = undefined;
    this.onValueChange(undefined);
  }

  private closeDialogRef(): void {
    this.dialogRef?.close();
  }
}
