import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-dialog-content-layout',
  templateUrl: './dialog-content-layout.component.html',
  styleUrls: ['./dialog-content-layout.component.scss'],
})
export class DialogContentLayoutComponent {
  @Output() closeEvent = new EventEmitter<void>();

  close(): void {
    this.closeEvent.emit();
  }
}
