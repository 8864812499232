<div class="header-container">
  <app-header-profile
    [profile]="profile"
    [items]="items"
    [alwaysShowTitle]="hideTopBarSearch$ | async"
    [avatarUrl]="avatarUrl$ | async"
  ></app-header-profile>
  <app-search-box
    *ngIf="(hideTopBarSearch$ | async) === false"
    [options]="filterOptions"
    i18n-placeholder
    placeholder="search.input.placeholder"
    (loadEvent)="navigateToSearchResultsPage($event)"
    [filter]="filterOptionValue.PROCESS"
  ></app-search-box>
</div>
