export enum AppRootRoute {
  SIGN_IN = 'sign-in',
  HOME = 'home',
  PROCESSES = 'processes',
  NEWS = 'news',
  WIKI = 'wiki',
  LANGUAGE = 'language',
  FILES = 'files',
  FILE_UPLOAD = 'file-upload',
  CONTENT_CATEGORIES = 'content-categories',
  QUICKLINKS = 'quicklinks',
  LOGOUT = 'logout',
  PROFILE = 'profile',
  SEARCH = 'search',
  DOCUMENT = 'document',
}

export enum AppActionRoute {
  DELETE = 'delete',
  CREATE = 'create',
  EDIT = 'edit',
}
