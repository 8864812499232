import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  constructor(@Inject(DOCUMENT) private readonly document: Document) {}

  setTenantTheme(tenant: string): void {
    const themeLink = this.document.getElementById(
      'tenant-theme'
    ) as HTMLLinkElement;

    if (themeLink) {
      themeLink.href = `assets/tenant-themes/${tenant}.css`;
    }
  }
}
