import { Injectable } from '@angular/core';
import { MenuGroups, MenuResponse } from '@app/shell/models/sidebar.models';
import { SidebarMapperService } from '@app/shell/services/mappers/sidebar-mapper.service';
import { HttpService } from '@avenir-client-web/http';
import { SingleResponse } from '@avenir-client-web/models';
import { menuApi } from '@shared/constants/api.constants';
import { map, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  constructor(
    private readonly httpService: HttpService,
    private readonly sidebarMapperService: SidebarMapperService
  ) {}

  menuChangedEvent$: Subject<void> = new Subject();

  getSidebarItems(): Observable<MenuGroups> {
    return this.httpService
      .get<SingleResponse<MenuResponse>>(menuApi)
      .pipe(map(({ data }) => this.sidebarMapperService.mapMenuResponse(data)));
  }
}
