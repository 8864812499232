<div class="sidebar-container">
  <a class="avenir-logo" routerLink="/">
    <img src="assets/images/svg/avenir-logo.svg" alt="logo" />
  </a>
  <div class="scrollable-wrapper pb-4">
    <ng-container [ngTemplateOutlet]="sidebarItems"></ng-container>
  </div>
  <div class="menu">
    <span class="menu-text" i18n>sidebar.menu</span>
    <a class="expand-button" type="button" (click)="toggleSidebar(true)">
      <span class="fa-solid fa-bars"></span>
    </a>
  </div>
</div>

<p-sidebar [(visible)]="isDisplay" position="right">
  <ng-container [ngTemplateOutlet]="sidebarHeader"></ng-container>
  <ng-container [ngTemplateOutlet]="sidebarItems"></ng-container>
</p-sidebar>

<ng-template #sidebarHeader>
  <div class="sidebar-header">
    <a (click)="toggleSidebar(false)">
      <span i18n>sidebar.close</span>
      <span class="fa-solid fa-xmark fa-xl"></span>
    </a>
  </div>
</ng-template>

<ng-template #sidebarItems>
  <ng-container *ngIf="sidebarItems$ | async as sidebarItems">
    <ng-container
      [ngTemplateOutletContext]="{ items: sidebarItems.topMenu }"
      [ngTemplateOutlet]="appSidebarItem"
    ></ng-container>

    <ng-container *ngIf="sidebarItems.processMenu.length">
      <div class="separator">
        <h2 class="text" i18n>sidebar.process</h2>
      </div>
      <ng-container
        [ngTemplateOutletContext]="{ items: sidebarItems.processMenu }"
        [ngTemplateOutlet]="appSidebarItem"
      ></ng-container>
    </ng-container>

    <ng-container
      *ngxPermissionsOnly="[
        permission.WRITE.toString(),
        permission.ADMIN.toString()
      ]"
    >
      <div class="separator">
        <h2 class="text" i18n>sidebar.tenantAdmin</h2>
      </div>
      <ng-container
        [ngTemplateOutletContext]="{ items: sidebarItems.adminMenu }"
        [ngTemplateOutlet]="appSidebarItem"
      ></ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #appSidebarItem let-items="items">
  <ng-container *ngFor="let item of items">
    <app-sidebar-item
      (click)="toggleSidebar(false)"
      *ngxPermissionsOnly="[item.state.permission]"
      [routerLinkActiveOptions]="
        isCategoryFormRouter() ? false : { exact: item.state.exact }
      "
      [routerLink]="item.url"
      [sidebarItem]="item"
      routerLinkActive="active"
    ></app-sidebar-item>
  </ng-container>
</ng-template>
