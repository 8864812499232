import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-mail-to-button',
  templateUrl: './mail-to-button.component.html',
  styleUrls: ['./mail-to-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MailToButtonComponent {
  MAIL_REF = 'mailto:personal@stadtluzern.ch';
}
