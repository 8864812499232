import { SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';

export const MyProfileRoute = 'me';

export const SkillLimit = 24;

export enum ProfileSection {
  DESCRIPTION = 'description',
  SKILLS = 'skills',
}

export const SkillStrength: { [key: string]: string } = {
  0: $localize`profile.skillStrength.informed`,
  1: $localize`profile.skillStrength.experienced `,
  2: $localize`profile.skillStrength.expert`,
};

export interface ProfileDetail {
  id?: number;
  aadId: string;
  name: string;
  jobTitle: string;
  businessPhones: string[];
  mobilePhone: string;
  email: string;
  officeLocation: string;
  description: string;
  skills: SkillDetail[];
  avatar$?: Observable<SafeUrl>;
  department?: string;
}

export interface SkillDetail {
  id: number;
  order?: number;
  name: string;
  level: number;
}

export type UpdateField = 'skills' | 'description';
export interface ProfileUpdate {
  op?: 'replace';
  path: UpdateField;
  value: SkillDetail[] | string;
}
