import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FileService } from '@avenir-client-web/file';
import { ProgressSpinnerService } from '@avenir-client-web/progress-spinner';
import { CredentialsService } from '@core/authentication/credentials.service';
import { AppRootRoute } from '@core/enums/app-route.enum';
import { PermissionService } from '@core/services/permission.service';
import { finalize, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-dummy',
  template: '',
})
export class DummyComponent implements OnInit, OnDestroy {
  roleId;

  fileId: string;

  private readonly compUnsubscribe$ = new Subject<void>();

  constructor(
    private readonly permissionService: PermissionService,
    private readonly route: ActivatedRoute,
    private readonly progressSpinnerService: ProgressSpinnerService,
    private readonly router: Router,
    private readonly credentialsService: CredentialsService,
    private readonly fileService: FileService
  ) {
    this.roleId = +this.route.snapshot.params.roleId;
    this.fileId = this.route.snapshot.params.fileId;
  }

  ngOnDestroy(): void {
    this.compUnsubscribe$.next();
    this.compUnsubscribe$.complete();
  }

  ngOnInit(): void {
    if (this.roleId) {
      this.progressSpinnerService.showProgressSpinner(true);
      this.permissionService
        .setCurrentRoleDummy(this.roleId)
        .pipe(
          finalize(() =>
            this.progressSpinnerService.showProgressSpinner(false)
          ),
          takeUntil(this.compUnsubscribe$)
        )
        .subscribe(() => {
          this.router.navigate([AppRootRoute.HOME]);

          const credential = {
            ...this.credentialsService.getCredentials(),
            role: {
              id: this.roleId,
              isCurrent: true,
            },
          };

          this.credentialsService.setCredentials(credential);
          this.permissionService.roleDummyChanges$.next();
        });
    }

    if (this.fileId) {
      this.fileService
        .deleteFileDummy(this.fileId)
        .subscribe(() => this.router.navigate([AppRootRoute.FILES]));
    }
  }
}
