export const languageApi = 'languages';

export const profileApi = 'profile';

export const menuApi = 'menus';

export const contentCategoryApi = 'content-categories';

export const dashboardContentCategoryApi = `dashboard/${contentCategoryApi}`;

export const contentCategoryMetadataApi = 'content-categories/metadata';

export const searchUsersApi = 'users/search';

export const loginProcedureApi = 'users/login-procedure';

export const tenantRoleApi = 'user-roles';

export const newsApi = 'news';

export const latestNewsApi = `${newsApi}/latest`;

export const setCurrentRoleApi = `${profileApi}/update/set-current-role`;

export const processApi = 'processes';

export const processMetadataApi = `${processApi}/metadata`;

export const quicklinkApi = 'quicklinks';

export const detailsQuicklinkApi = `${quicklinkApi}/detail`;

export const wikiIndexApi = 'wiki-indexes';

export const detailWikiIndexApi = `${wikiIndexApi}/detail`;

export const wikiDetailApi = 'wiki-details';

export const searchProcessApi = 'search/process';

export const searchNewsApi = 'search/news';

export const searchWikiApi = 'search/wiki';

export const searchAzUsersApi = 'search/az-users';

export const searchLearningSessionsApi = 'search/learning';

export const dashboardQuicklinksApi = `dashboard/${quicklinkApi}`;

export const dashboardNewsApi = `dashboard/${newsApi}`;

export const workingHoursChartApi = `dashboard/working-hours`;

export const holidaysChartApi = `dashboard/holidays`;
