import { Component } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-backward-confirmation',
  templateUrl: './backward-confirmation.component.html',
  styleUrls: ['./backward-confirmation.component.scss'],
})
export class BackwardConfirmationComponent {
  constructor(private readonly dialogRef: DynamicDialogRef) {}

  close(isConfirm: boolean): void {
    this.dialogRef.close(isConfirm);
  }
}
