<div class="search-filter-tabs">
  <h3 class="search-filter-tabs__header">{{ filterTitle }}</h3>

  <p-tabView
    styleClass="search-filter-tabs__tabview"
    (onChange)="filterChange.emit($event?.index)"
    [(activeIndex)]="filter"
  >
    <p-tabPanel
      *ngFor="let option of options"
      [header]="option.label"
    ></p-tabPanel>
  </p-tabView>
</div>

<app-dropdown
  styleClass="search-filter-dropdown primary"
  [options]="options"
  (valueChange)="filterChange.emit($event)"
  scrollHeight="100%"
  [value]="filter"
></app-dropdown>
