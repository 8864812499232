<div class="container">
  <ng-container [ngSwitch]="true">
    <a
      *ngSwitchCase="!!route"
      [routerLink]="route"
      [target]="target"
      class="toggle-collapse-button"
      [class.disabled]="disabled"
    >
      <span class="fa-solid" [ngClass]="styleClass"></span>
    </a>
    <a
      *ngSwitchCase="!!href"
      [href]="href"
      [target]="target"
      class="toggle-collapse-button"
      rel="noopener noreferrer"
      [class.disabled]="disabled"
    >
      <span class="fa-solid" [ngClass]="styleClass"></span>
    </a>
    <a
      *ngSwitchDefault
      class="toggle-collapse-button"
      [class.disabled]="disabled"
      (click)="clicked.emit()"
    >
      <span class="fa-solid" [ngClass]="styleClass"></span>
    </a>
  </ng-container>
</div>
