import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AvatarModule } from '@avenir-client-web/avatar';
import { FooterModule } from '@avenir-client-web/footer';
import { MailToButtonModule } from '@avenir-client-web/mail-to-button';
import { SearchModule } from '@avenir-client-web/search';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SidebarModule } from 'primeng/sidebar';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { HeaderProfileComponent } from './components/header-profile/header-profile.component';
import { SidebarItemComponent } from './components/sidebar-item/sidebar-item.component';
import { HeaderComponent } from './containers/header/header.component';
import { SideBarComponent } from './containers/sidebar/sidebar.component';
import { ShellComponent } from './shell.component';

@NgModule({
  declarations: [
    ShellComponent,
    SideBarComponent,
    SidebarItemComponent,
    HeaderComponent,
    HeaderProfileComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgxPermissionsModule.forChild(),
    SearchModule,
    AvatarModule,
    TieredMenuModule,
    SidebarModule,
    FooterModule,
    MailToButtonModule,
  ],
})
export class ShellModule {}
