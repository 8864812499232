<div class="search-section pt-4 pb-6">
  <h1 class="search-section__title">{{ sectionTitle }}</h1>

  <app-search-box
    [options]="options"
    [placeholder]="placeholder"
    (loadEvent)="loadEvent.emit($event)"
    [keyword]="keyword"
    [filter]="filter"
  ></app-search-box>

  <p class="search-section__result-quantity">
    {{ resultQuantity }} {{ resultsFoundText }}
  </p>
</div>
