<div *ngIf="fileUrl$ | async as fileUrl" class="file-preview-container">
  <ng-container *ngIf="isPdfFile; else imagePreview">
    <app-pdf-viewer [url]="fileUrl" [fileName]="fileName"></app-pdf-viewer>
  </ng-container>
  <ng-template #imagePreview>
    <button
      pButton
      pRipple
      type="button"
      icon="pi pi-download"
      class="p-button-rounded p-button-text"
      (click)="downloadFile()"
    ></button>
    <div class="image-container">
      <img [src]="fileUrl | safe: securityContext.URL" [alt]="fileName" />
    </div>
  </ng-template>
</div>
<div class="file-error" *ngIf="hasError">
  <span class="pi pi-image"></span>
</div>
