export interface FilterOption<T> {
  label: string;
  value: T;
}

export interface SearchResult {
  id: number | string;
  title: string;
  description: string;
  url: string;
}

export interface QueryOption<T> {
  keyword: string;
  filter: T;
}
