import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { ImageType } from '../../enums/image-types.enum';
import { ImageService } from '../../services/image.service';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ImageComponent implements OnChanges {
  @Input() imageId: string;

  @Input() alt: string;

  @Input() isThumbnails = false;

  @Input() styleClass = '';

  @Input() isSvg: boolean;

  @Input() isInlineSvg = true;

  @ViewChild('image', { static: true }) image: ElementRef;

  imageUrl$: Observable<SafeUrl>;

  hasError = false;

  isLoading = false;

  constructor(private readonly imageService: ImageService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.imageId) {
      if (!changes.imageId.currentValue) {
        this.imageUrl$ = of(undefined);
      } else {
        this.isLoading = true;
        this.imageUrl$ = this.imageService.getImageUrl(
          this.imageId,
          this.isThumbnails,
          this.isSvg
        );
      }
    }
  }

  onChangeErrorState(state: boolean): void {
    this.hasError = state;
    this.isLoading = false;
    this.isSvg && this.isInlineSvg && !this.hasError && this.convertImages();
  }

  private convertImages(): void {
    const image = this.image.nativeElement;

    fetch(image.src)
      .then(res => res.text())
      .then((data: string) => {
        const parser = new DOMParser();
        const svg: SVGElement = parser
          .parseFromString(data, ImageType.SVG)
          .querySelector('svg');

        const currentSvg = image.parentNode?.children[1];

        !!currentSvg && image.parentNode.removeChild(currentSvg);
        image.parentNode.appendChild(svg);
      });
  }
}
