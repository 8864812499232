<app-dialog-content-layout (closeEvent)="close()">
  <div class="upload-wrapper">
    <span class="title" i18n>uploadFile.title</span>
    <div *ngIf="selectedFile" class="selected-file">
      <span>{{ selectedFile.name }}</span>
    </div>
    <div class="drag-drop-section">
      <span class="pi pi-upload"></span>
      <span class="upload-your-file" i18n>uploadFile.uploadYourFile</span>
      <div class="file-picker-wrapper">
        <label
          *ngIf="!selectedFile; else replaceBtnTemplate"
          class="file-picker-btn"
          for="file-picker"
          i18n
          >uploadFile.uploadFromYourDevice</label
        >
        <ng-template #replaceBtnTemplate>
          <label class="file-picker-btn" for="file-picker" i18n
            >uploadFile.replaceTheUploadedFile</label
          >
        </ng-template>
        <input
          id="file-picker"
          class="file-picker"
          type="file"
          [accept]="acceptFileTypes.toString()"
          (change)="handleFileInput($event)"
        />
      </div>
    </div>
    <div class="action">
      <app-button
        i18n-label
        label="default.action.save"
        (click)="save()"
      ></app-button>
    </div>
  </div>
</app-dialog-content-layout>
