<div class="confirmation-wrapper">
  <span class="title" i18n>default.confirmation.title</span>
  <span class="message" i18n>backwardConfirmation.message</span>
  <div class="action">
    <app-button
      i18n-label
      label="default.action.cancel"
      (click)="close(false)"
      styleClass="cancel-btn"
    ></app-button>
    <app-button
      i18n-label
      label="default.action.ok"
      (click)="close(true)"
    ></app-button>
  </div>
</div>
