import { Injectable } from '@angular/core';
import { StorageService } from '@avenir-client-web/storage';
import { ThemeService } from '@avenir-client-web/theme';
import { Credentials } from '@core/models/credentials.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

const credentialsKey = 'credentials';

@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  onRoleSwitched$ = new Subject<void>();

  private credentials: Credentials;

  private readonly credentialsChangesSubject$ =
    new BehaviorSubject<Credentials>(null);

  constructor(
    private readonly storage: StorageService,
    private readonly themeService: ThemeService
  ) {}

  getCredentials(): Credentials {
    return this.credentials;
  }

  credentialsChanges(): Observable<Credentials> {
    return this.credentialsChangesSubject$.asObservable();
  }

  setCredentials(credentials: Credentials): void {
    this.credentials = credentials;
    this.storage.set(credentialsKey, JSON.stringify(this.credentials));
    this.credentialsChangesSubject$.next(this.credentials);
    this.setTheme();
  }

  removeCredentials(): void {
    this.credentials = null;
    this.storage.remove(credentialsKey);
  }

  getCredentialFromStorage(): Credentials {
    this.credentials = JSON.parse(this.storage.get(credentialsKey));
    this.credentialsChangesSubject$.next(this.credentials);
    this.setTheme();

    return this.credentials;
  }

  private setTheme(): void {
    if (this.credentials?.tenant) {
      this.themeService.setTenantTheme(this.credentials.tenant);
    }
  }
}
