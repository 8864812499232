import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CollapseButtonComponent } from './collapse-button.component';

@NgModule({
  declarations: [CollapseButtonComponent],
  imports: [CommonModule, RouterModule],
  exports: [CollapseButtonComponent],
})
export class CollapseButtonModule {}
