import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControlComponent } from '../../../base-control/base-control.component';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true,
    },
  ],
})
export class DropdownComponent extends BaseControlComponent {
  @Input() scrollHeight = '200px';

  @Input() styleClass: string;

  @Input() options: unknown[];

  @Input() optionLabel: string;

  @Input() optionValue: string;

  @Input() virtualScroll = false;

  @Output() valueChange = new EventEmitter<unknown>();
}
