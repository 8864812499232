<div class="shell-wrapper" #shellWrapper appResizeObserver>
  <app-sidebar></app-sidebar>
  <div class="main-content-wrapper">
    <app-header></app-header>
    <div #scrollableWrapper class="scrollable-wrapper">
      <div class="content">
        <router-outlet></router-outlet>
      </div>
      <app-footer></app-footer>
    </div>
  </div>
</div>
<app-mail-to-button></app-mail-to-button>
