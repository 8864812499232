import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ShellService } from '@app/shell/services/shell.service';
import { ResizeObserverService } from '@avenir-client-web/resize-observer';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit, OnDestroy {
  @ViewChild('scrollableWrapper', { static: true })
  scrollableWrapper: ElementRef<HTMLDivElement>;

  @ViewChild('shellWrapper', { static: true })
  shellWrapper: ElementRef<HTMLDivElement>;

  private readonly compUnsubscribe$ = new Subject<void>();

  constructor(
    private readonly shellService: ShellService,
    private readonly resizeObserverService: ResizeObserverService
  ) {}

  ngOnDestroy(): void {
    this.compUnsubscribe$.next();
    this.compUnsubscribe$.complete();
  }

  ngOnInit(): void {
    this.subscribeScrollToTop();
    this.subscribeResizeObserver();
  }

  private subscribeResizeObserver(): void {
    this.resizeObserverService
      .notifyWhenResize(this.shellWrapper)
      .pipe(takeUntil(this.compUnsubscribe$))
      .subscribe(result => {
        this.shellService.resizeSubject$.next(result.contentRect.width);
      });
  }

  private subscribeScrollToTop(): void {
    this.shellService.scrollToTop$
      .pipe(takeUntil(this.compUnsubscribe$))
      .subscribe(() => {
        this.scrollableWrapper.nativeElement.scrollTo(0, 0);
      });
  }
}
