export const filesMock = [
  {
    name: 'This is File Name 001',
    size: 550,
    extension: 'PDF',
    id: 1,
  },
  {
    name: 'This is File Name 002',
    size: 1500000,
    extension: 'PDF',
    id: 2,
  },
  {
    name: 'This is File Name 003',
    size: 130000,
    extension: 'PDF',
    id: 3,
  },
  {
    name: 'This is File Name 004',
    size: 130000,
    extension: 'PDF',
    id: 4,
  },
  {
    name: 'This is File Name 005',
    size: 130000,
    extension: 'PDF',
    id: 5,
  },
  {
    name: 'This is File Name 006',
    size: 130000,
    extension: 'PDF',
    id: 6,
  },
  {
    name: 'This is File Name 007',
    size: 130000,
    extension: 'PDF',
    id: 7,
  },
  {
    name: 'This is File Name 008',
    size: 130000,
    extension: 'PDF',
    id: 8,
  },
  {
    name: 'This is File Name 009',
    size: 130000,
    extension: 'PDF',
    id: 9,
  },
  {
    name: 'This is File Name 010',
    size: 130000,
    extension: 'PDF',
    id: 10,
  },
  {
    name: 'This is File Name 011',
    size: 130000,
    extension: 'PDF',
    id: 11,
  },
  {
    name: 'This is File Name 012',
    size: 130000,
    extension: 'PDF',
    id: 12,
  },
];
