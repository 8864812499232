import { Component, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

@Component({
  template: '',
})
export class BaseControlComponent<T = unknown> implements ControlValueAccessor {
  @Input() placeholder: string;

  @Input() label: string;

  @Input() hasAsterisk = false;

  @Input() value: T;

  disabled = false;

  private onChange: (value: T) => void;

  private onTouched: () => void;

  writeValue(value: T): void {
    this.value = value;
  }

  registerOnChange(fn: (value: T) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onValueChange(value: T): void {
    if (this.onChange) {
      this.onChange(value);
      this.writeValue(value);
    }
  }
}
