import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@avenir-client-web/button';
import { DialogContentLayoutModule } from '@avenir-client-web/dialog-content-layout';
import { PdfViewerModule } from '@avenir-client-web/pdf-viewer';
import { SafePipeModule } from '@avenir-client-web/safe-pipe';
import { ButtonModule as PButtonModule } from 'primeng/button';
import { FilePreviewComponent } from './components/file-preview/file-preview.component';
import { UploadFileComponent } from './containers/upload-file/upload-file.component';

@NgModule({
  imports: [
    CommonModule,
    SafePipeModule,
    ButtonModule,
    PdfViewerModule,
    DialogContentLayoutModule,
    PButtonModule,
  ],
  declarations: [FilePreviewComponent, UploadFileComponent],
  exports: [FilePreviewComponent, UploadFileComponent],
})
export class FileModule {}
