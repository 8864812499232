import { AppRootRoute } from '@core/enums/app-route.enum';
import { SidebarItemKey } from '@core/enums/sidebar-item.enum';
import { Permission } from '@core/services/permission.model';
import { MenuItem } from 'primeng/api';

export type Menu = {
  [key in SidebarItemKey]: MenuItem;
};

export const menu: Menu = {
  [SidebarItemKey.DASHBOARD]: {
    label: $localize`menuItems.home`,
    url: `/${AppRootRoute.HOME}`,
    state: {
      permission: Permission.READ,
    },
  },
  [SidebarItemKey.NEWS]: {
    label: $localize`menuItems.news`,
    url: `/${AppRootRoute.NEWS}`,
    state: {
      permission: Permission.READ,
    },
  },
  [SidebarItemKey.WIKI]: {
    label: $localize`menuItems.wiki`,
    url: `/${AppRootRoute.WIKI}`,
    state: {
      permission: Permission.READ,
    },
  },
  [SidebarItemKey.PROCESSES]: {
    label: $localize`menuItems.processes`,
    url: `/${AppRootRoute.PROCESSES}`,
    state: {
      permission: Permission.WRITE,
    },
  },
  [SidebarItemKey.FILES]: {
    label: $localize`menuItems.files`,
    url: `/${AppRootRoute.FILES}`,
    state: {
      permission: Permission.WRITE,
    },
  },
  [SidebarItemKey.CONTENT_CATEGORIES]: {
    label: $localize`menuItems.content-categories`,
    url: `/${AppRootRoute.CONTENT_CATEGORIES}`,
    state: {
      permission: Permission.WRITE,
      exact: true,
    },
  },
  [SidebarItemKey.QUICKLINKS]: {
    label: $localize`menuItems.quicklinks`,
    url: `/${AppRootRoute.QUICKLINKS}`,
    state: {
      permission: Permission.WRITE,
    },
  },
  [SidebarItemKey.LANGUAGE]: {
    label: $localize`menuItems.language`,
    url: `/${AppRootRoute.LANGUAGE}`,
    state: {
      permission: Permission.ADMIN,
    },
  },
};
