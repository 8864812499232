<div class="search-box-container">
  <div class="search-box">
    <app-dropdown
      styleClass="search-box__dropdown"
      [options]="options"
      (valueChange)="filter = $event"
      [value]="filter"
      scrollHeight="100%"
    ></app-dropdown>

    <span class="search-box-field p-input-icon-left">
      <span class="search-box-field__icon">
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            stroke="currentColor"
            stroke-width="2"
            fill="none"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path
              d="M16.199 5.337A8.053 8.053 0 1 1 1.91 12.773a8.053 8.053 0 0 1 14.288-7.436z"
            />
            <path
              d="M4.92 11.206A4.667 4.667 0 0 1 6.903 4.92M16.087 13.216l4.318 4.318a2.03 2.03 0 1 1-2.871 2.871l-4.318-4.318"
            />
          </g>
        </svg>
      </span>

      <form action="" (ngSubmit)="emitLoadEvent()">
        <input
          class="search-box-field__input"
          type="search"
          name="search"
          pInputText
          [placeholder]="placeholder"
          [(ngModel)]="keyword"
          [maxlength]="inputMaxLength"
          autocomplete="off"
        />
      </form>

      <app-collapse-button
        class="search-box-field__button"
        (clicked)="emitLoadEvent()"
        [disabled]="!keyword?.length"
      ></app-collapse-button>
    </span>
  </div>
</div>
