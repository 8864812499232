import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { CredentialsService } from '@core/authentication/credentials.service';
import { AppRootRoute } from '@core/enums/app-route.enum';
import { PermissionService } from '@core/services/permission.service';
import { filter, first, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserFeatureGuard implements CanLoad, CanActivate {
  constructor(
    private readonly router: Router,
    private readonly credentialsService: CredentialsService,
    private readonly permissionService: PermissionService
  ) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.validateUserPermissions(route);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.validateUserPermissions(route);
  }

  private validateUserPermissions(
    route: Route | ActivatedRouteSnapshot
  ): Observable<boolean> {
    return this.credentialsService.credentialsChanges().pipe(
      filter(response => !!response),
      map(() => {
        if (!this.permissionService.checkScreenAccess(route.data.permission)) {
          this.router.navigate([AppRootRoute.HOME]);

          return false;
        }

        return true;
      }),
      first()
    );
  }
}
