import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProgressSpinnerService {
  private readonly progressSpinnerSubject$ = new Subject<boolean>();

  get isProgressSpinnerShown$(): Observable<boolean> {
    return this.progressSpinnerSubject$.asObservable();
  }

  showProgressSpinner(show: boolean): void {
    this.progressSpinnerSubject$.next(show);
  }
}
