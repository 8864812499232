import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FileItem, FileService } from '@avenir-client-web/file';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileUpload } from 'primeng/fileupload';
import {
  catchError,
  finalize,
  Observable,
  pluck,
  Subject,
  takeUntil,
  tap,
  throwError,
} from 'rxjs';
import { ImageType, ImageTypeForUpload } from '../../enums/image-types.enum';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadImageComponent implements OnInit, OnDestroy {
  @ViewChild(FileUpload) fileUpload: FileUpload;

  @ViewChild(CdkVirtualScrollViewport)
  viewport: CdkVirtualScrollViewport;

  @ViewChild('gridItem') set gridItem(value: ElementRef) {
    const newItemWidth = value?.nativeElement?.offsetWidth;

    if (!newItemWidth || this.itemWidth === newItemWidth) return;

    this.itemWidth = newItemWidth;

    if (this.uploadedImages.length > 0) {
      this.onResize();
    } else {
      this.getImages();
    }
  }

  @ViewChild('viewportWrapper', { static: true })
  viewportWrapper: ElementRef;

  acceptImageFileTypes = [ImageType.PNG, ImageType.JPEG];

  acceptType: string;

  itemSet: FileItem[][] = [[undefined]];

  itemWidth: number;

  itemsPerRow = 1;

  selectedFile: FileItem;

  uploadedImages: FileItem[] = [];

  isLoaded = false;

  private readonly MAX_FILE_SIZE = 10485760;

  private readonly resizeObserver = new ResizeObserver(() => this.onResize());

  private readonly compUnsubscribe$ = new Subject<void>();

  constructor(
    readonly dialogConfig: DynamicDialogConfig,
    private readonly messageService: MessageService,
    private readonly dialogRef: DynamicDialogRef,
    private readonly fileService: FileService,
    private readonly cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.setAcceptImageFileTypes();
    this.resizeObserver.observe(this.viewportWrapper.nativeElement);
  }

  ngOnDestroy(): void {
    this.compUnsubscribe$.next();
    this.compUnsubscribe$.complete();
    this.resizeObserver.unobserve(this.viewportWrapper.nativeElement);
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  close(): void {
    this.dialogRef.close(this.selectedFile);
  }

  onSelectedImage(image: FileItem): void {
    this.selectedFile = image;
  }

  handleFileInput({ files }: { files: File[] }): void {
    const file = files[0];

    if (!file) {
      return;
    }

    if (!this.acceptImageFileTypes.includes(file.type as ImageType)) {
      this.showIncorrectFileTypeMessage();
      this.fileUpload.clear();

      return;
    }

    if (file.size > this.MAX_FILE_SIZE) {
      this.showIncorrectFileSizeMessage();
      this.fileUpload.clear();

      return;
    }

    this.save(file);
  }

  save(file: File): void {
    const result: Observable<FileItem> = this.fileService
      .uploadImage(file)
      .pipe(
        catchError(error => {
          this.showFailedUploadMessage();

          return throwError(() => error);
        }),
        tap(() => this.showSuccessUploadMessage()),
        pluck('data')
      );

    this.dialogRef.close(result);
  }

  private onResize(): void {
    const itemsSize = this.generateItemsSize();

    if (this.itemsPerRow === itemsSize) return;

    this.itemSet = this.generateDataChunk(this.uploadedImages, itemsSize);
    this.itemsPerRow = itemsSize;
    this.cd.detectChanges();
  }

  private generateDataChunk(
    data: FileItem[],
    chunkSize: number = this.itemsPerRow
  ): FileItem[][] {
    if (data.length === 0) return [[undefined]];

    const dataChunk: FileItem[][] = [];

    for (
      let index = 0;
      index < data.length;
      index += index ? chunkSize : chunkSize - 1
    ) {
      const imagesPerRow: number = index !== 0 ? chunkSize : chunkSize - 1; // for upload button slot
      const chunk: FileItem[] = data.slice(index, index + imagesPerRow);
      const difference: number = imagesPerRow - chunk.length;

      difference && chunk.push(...Array(difference)); // generate spacer when items per row less than chunk size
      dataChunk.push(chunk);
    }

    return dataChunk;
  }

  private generateItemsSize(): number {
    const GAP = 12;
    const viewportInnerWidth =
      this.viewport.elementRef.nativeElement.clientWidth;

    return Number.parseInt(String(viewportInnerWidth / (this.itemWidth + GAP)));
  }

  private getImages(): void {
    this.fileService
      .getImages(this.acceptImageFileTypes)
      .pipe(
        finalize(() => {
          this.isLoaded = true;
          this.cd.detectChanges();
        }),
        takeUntil(this.compUnsubscribe$)
      )
      .subscribe((res: FileItem[]) => {
        this.uploadedImages = res;
        this.itemSet = this.generateDataChunk(res);
      });
  }

  private showSuccessUploadMessage(): void {
    this.messageService.clear();

    this.messageService.add({
      severity: 'success',
      summary: $localize`uploadFile.uploadSuccess`,
    });
  }

  private showFailedUploadMessage(): void {
    this.messageService.clear();

    this.messageService.add({
      severity: 'error',
      summary: $localize`uploadFile.uploadFailed`,
    });
  }

  private showIncorrectFileTypeMessage(): void {
    this.messageService.clear();

    this.messageService.add({
      severity: 'error',
      summary: $localize`uploadFile.incorrectFileType`,
    });
  }

  private showIncorrectFileSizeMessage(): void {
    this.messageService.clear();

    this.messageService.add({
      severity: 'error',
      summary: $localize`uploadFile.incorrectFileSize`,
    });
  }

  private setAcceptImageFileTypes(): void {
    const acceptImageFileTypes = this.dialogConfig.data?.acceptImageFileTypes;

    if (acceptImageFileTypes) {
      this.acceptImageFileTypes = acceptImageFileTypes;
    }

    this.acceptType = this.mapAcceptImageForUpload(this.acceptImageFileTypes);
  }

  private mapAcceptImageForUpload(acceptType: ImageType[]): string {
    return acceptType
      .map(item => {
        item = Object.keys(ImageType)[
          Object.values(ImageType).indexOf(item)
        ] as ImageType;

        return ImageTypeForUpload[item];
      })
      .join(', ');
  }
}
