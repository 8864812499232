import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeValue } from '@angular/platform-browser';

@Pipe({
  name: 'safe',
})
export class SafePipe implements PipeTransform {
  constructor(private readonly sanitizer: DomSanitizer) {}

  transform(content: string, context?: SecurityContext): SafeValue {
    if (!content) {
      return '';
    }

    switch (context) {
      case SecurityContext.URL:
        return this.sanitizer.bypassSecurityTrustUrl(content);
      case SecurityContext.STYLE:
        return this.sanitizer.bypassSecurityTrustStyle(content);
      default:
        return this.sanitizer.bypassSecurityTrustHtml(content);
    }
  }
}
