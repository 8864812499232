import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { ProgressSpinnerService } from '../services/progress-spinner.service';

@Injectable()
export class ProgressSpinnerInterceptor implements HttpInterceptor {
  constructor(
    private readonly progressSpinnerService: ProgressSpinnerService
  ) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((error: unknown) => {
        this.progressSpinnerService.showProgressSpinner(false);

        throw error;
      })
    );
  }
}
