import { Component, EventEmitter, Input, Output } from '@angular/core';

type Target = '_blank' | '_self';

@Component({
  selector: 'app-collapse-button',
  templateUrl: './collapse-button.component.html',
  styleUrls: ['./collapse-button.component.scss'],
})
export class CollapseButtonComponent {
  @Input() route: string[];

  @Input() href: string;

  @Input() styleClass = 'fa-angle-right';

  @Input() target: Target = '_self';

  @Input() disabled: boolean;

  @Output() clicked = new EventEmitter<void>();
}
