export interface SingleResponse<T> {
  success: boolean;
  statusCode: number;
  data: T;
}

export type ListResponse<T> = SingleResponse<T[]>;

export interface PagingResponse<T> extends ListResponse<T> {
  pageSize: number;
  pageNumber: number;
  totalPages: number;
  totalRecords: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface AzSearchResponse<T> {
  count?: number;
  skipToken?: string;
  value: T[];
}

export interface RequestOption<T = Paging> {
  paging?: T;
  sortBy?: string;
  filter?: Filter;
}

export interface Paging {
  pageSize: number;
  pageNumber: number;
}

type Filter = {
  [key: string]: string | number;
};

export interface AzPaging {
  top: number;
  skipToken?: string;
  orderBy?: string;
}
