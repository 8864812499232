import { HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { CredentialsService } from '@core/authentication/credentials.service';
import { AppRootRoute } from '@core/enums/app-route.enum';
import { Credentials } from '@core/models/credentials.model';
import { PermissionService } from '@core/services/permission.service';
import { ProfileService } from '@core/services/profile.service';
import {
  catchError,
  filter,
  forkJoin,
  Subject,
  Subscription,
  takeUntil,
  throwError,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthMsalService implements OnDestroy {
  private readonly compUnsubscribe$ = new Subject<void>();

  constructor(
    private readonly credentialsService: CredentialsService,
    private readonly msalService: MsalService,
    @Inject(MSAL_GUARD_CONFIG)
    private readonly msalGuardConfig: MsalGuardConfiguration,
    private readonly msalBroadcastService: MsalBroadcastService,
    private readonly permissionService: PermissionService,
    private readonly profileService: ProfileService,
    private readonly router: Router
  ) {}

  ngOnDestroy(): void {
    this.compUnsubscribe$.next();
    this.compUnsubscribe$.complete();
  }

  loginRedirect(): void {
    this.msalService.loginRedirect({
      ...this.msalGuardConfig.authRequest,
    } as RedirectRequest);
  }

  logout(): void {
    this.msalService.logout();
    this.credentialsService.removeCredentials();
    this.profileService.onLogout();
  }

  initMsal(): void {
    this.listenMsalBroadcastEvents();
  }

  private listenMsalBroadcastEvents(): Subscription {
    return this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this.compUnsubscribe$)
      )
      .subscribe(() => {
        const accounts = this.msalService.instance.getAllAccounts();

        if (accounts.length) {
          this.checkAndSetActiveAccount();
        } else {
          this.loginRedirect();
        }
      });
  }

  private checkAndSetActiveAccount(): void {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length) {
      const accounts = this.msalService.instance.getAllAccounts();

      activeAccount = accounts[0];
      this.msalService.instance.setActiveAccount(activeAccount);
    }

    if (this.profileService.getUserActiveState()) {
      this.permissionService
        .loadPermissions()
        .pipe(takeUntil(this.compUnsubscribe$))
        .subscribe();

      forkJoin([
        this.permissionService.getUserPermissions(),
        this.profileService.getProfile(),
      ])
        .pipe(
          catchError(error => {
            if (error.status === HttpStatusCode.Forbidden) this.logout();

            return throwError(() => error);
          })
        )
        .subscribe(([permissions, profile]) => {
          if (!!activeAccount) {
            const defaultUserPermission =
              permissions.find(permission => permission.role.isCurrent) ??
              permissions.find(permission => permission.role.isDefault) ??
              permissions[0];

            this.credentialsService.setCredentials({
              id: profile.id,
              aadId: profile.aadId,
              role: defaultUserPermission.role,
              email: profile.email,
              userName: profile.userName,
              fullName: profile.name,
            } as Credentials);
          }
        });
    } else {
      this.router.navigate([AppRootRoute.SIGN_IN]);
    }
  }
}
