<div class="dialog-layout-wrapper">
  <button
    pButton
    pRipple
    type="button"
    icon="pi pi-times"
    class="close-btn p-button-rounded p-button-text"
    (click)="close()"
  ></button>
  <ng-content></ng-content>
</div>
